@keyframes animate-scroll-down-1 {
  0% {
    width: 0%;
  }
  100% {
    width: 100px;
  }
}

@keyframes animate-scroll-down-2 {
  0% {
    width: 0%;
  }
  100% {
    width: 75px;
  }
}

@keyframes animate-scroll-down-3 {
  0% {
    width: 0%;
  }
  100% {
    width: 50px;
  }
}

@keyframes animate-scroll-down-4 {
  0% {
    width: 0%;
  }
  100% {
    width: 25px;
  }
}

.animate-scroll-down-1 {
  animation: animate-scroll-down-1 1s ease-in-out 1s forwards;
}

.animate-scroll-down-2 {
  animation: animate-scroll-down-2 1s ease-in-out 1s forwards;
}

.animate-scroll-down-3 {
  animation: animate-scroll-down-3 1s ease-in-out 1s forwards;
}

.animate-scroll-down-4 {
  animation: animate-scroll-down-4 1s ease-in-out 1s forwards;
}
